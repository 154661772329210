* {
  box-sizing: border-box;
}
/* section {
  padding: 200px;
} */

/* .aboutieeecontainer h1 {
  font-size: 50px !important;
  font-weight: 600;
} */

.aboutieeecontainer p {
  font-size: 20px !important;
  font-weight: 300;
  line-height: 53px;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
}

.whatisieeecontainer {
  margin-top: 50px;
  padding: 30px;
  /* width: 1198px; */
  /* max-height: 912px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border: red 2px solid; */
  /* position: relative; */
  /* margin-right: auto;
  margin-left: auto; */
}

/* .whatisieeecontainer h1 {
  font-size: 50px !important;
  font-weight: 600;
  line-height: 70px;
  font-family: "Poppins", sans-serif;
} */

.whatisieeecontainer p {
  font-size: 15px !important;
  font-weight: 300;
  line-height: 53px;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
}

.leftwhatisieee {
  width: 50%;
  position: absolute;
  left: 0;
  /* padding-right: 50px; */
}

.rightwhatisieee {
  width: 50%;
  /* padding: 20px; */
}

.leftlist {
  list-style-type: circle;

  margin-bottom: 20px;
}

.aboutimage {
  /* width: 150px; */
}

.aboutieeeyoungcontainer {
  margin-top: 100px;

  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border: red 2px solid; */
  position: relative;
}

.leftwhatisieeeyoung {
  width: 50%;
  position: absolute;
  left: 0;
}

.specialheading {
  /* width: 545px; */
}

/* .aboutieeeyoungcontainer h1 {
  font-size: 50px !important;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 70px;
  font-family: "Poppins", sans-serif;
} */

.aboutieeeyoungcontainer p {
  font-size: 15px !important;
  font-weight: 300;
  line-height: 53px;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
}

.aboutieeeyoungcontainerimg {
  /* width: 80px; */
}

.specialtext {
  font-weight: bold;
}

.flexspecialtext {
  font-weight: bold;
}

.flexlist {
  list-style: circle !important;
  list-style-type: circle;
  margin-bottom: 20px;
  /* position: relative;
     left: 50px; */
  padding-left: 50px;
}

/* respnsive */
.sac {
  /* width: 400px; */
}

.sb {
  /* width: 200px; */
}
