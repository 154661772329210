.programHeader {
  height: 100vh;
  background-image: url("../../Images/ourprogram.png");
  background-size: cover;
  background-position: center center;
}
tr {
  margin-bottom: 30px;
}
td {
  /* border-radius: 3px; */
  padding: 10px;
  box-shadow: inset 0 5px 5px rgba(0, 0, 0, 0.048);
}
th {
  padding: 12px;
}
td:hover {
  background-color: rgba(0, 41, 39, 0.045);
}
@media (min-width: 1281px) {
}

@media (min-width: 1025px) and (max-width: 1280px) {
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .programTitle {
    font-size: 5rem !important;
    text-align: center;
    margin-left: 0 !important;
    margin-top: 30px;
  }
  .programTimeLineImage {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  .programTimeLineImage {
    font-size: 3rem !important;
    text-align: center;
    margin-left: 0 !important;
  }
  td {
    font-size: 0.7rem;
  }
  .programTimeLineImage {
    width: 100%;
  }
  .section2 {
    padding: 40px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  td {
    font-size: small;
  }
  .programTitle {
    font-size: 3rem !important;
    text-align: center;
    margin-left: 0 !important;
    margin-top: 30px;
  }
  .programTimeLineImage {
    width: 100%;
  }
  .section2 {
    padding: 0 !important;
  }
}
