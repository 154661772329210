/* .headcontainer {
    width: 100%;
} */

.head {
  width: 100%;
  /* min-height: 100vh; */
  background: url("../../../../Images/ABOUTUS\ 1.png");
  height: 100vh;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headtitle {
  color: white;
  font-size: 100px;
  /* font-family: 'Poppins', sans-serif; */
}
