.faqimage{
    background: url(../../Images/FAQ.png);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
}

.Header{
    font-size: 10rem;
    color: white;
    font-weight: 600;
}

.Content{
    margin: 110px 110px 0px 110px;
}

.Topic{
    font-size: 60px;
    font-weight: 700;
}

.Details{
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: 300;
    font-size: 24px;
}

@media (max-width: 1024) {
    .Content{
        margin: 80px 80px 0px 80px;
    }
}


@media (max-width: 768px){
    .Content{
        margin: 50px 50px 0px 50px;
    }
    .Topic{
        font-size: 6vw;
    }
    
    .Details{
        margin-top: 20px;
        margin-bottom: 40px;
        font-weight: 300;
        font-size: 24px;
    }
}

@media (max-width: 580px){
    .Details{
        font-size: 3vw;
    }
}

    .a{
        color: #b6702a;
    }