@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

.headercontainer {
  position: relative;
}

.Headerr {
  background: url(../../Images/homeHeader.png);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
}

.Header {
  width: 100%;
  height: auto;
}

.Logo {
  position: absolute;
  top: 14%;
  left: 2%;
  width: 10%;
}

/* .Button{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
} */

.Titlecontainer {
  padding: 30px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(30, 30, 30, 0.47);
}

.Title {
  text-align: center;
  font-size: 5vw;
  color: white;
  font-weight: 600;
}

.Content {
  margin: 110px 110px 0px 110px;
}

.Topic {
  font-size: 60px;
  font-weight: 700;
}

.Details {
  margin-top: 20px;
  margin-bottom: 40px;
  font-weight: 300;
  font-size: 24px;
}

.imagecontainer {
  height: 500px;
}

.imagecontent {
  width: auto;
  max-height: 100%;
  margin-left: auto;
  object-fit: contain;
}

.object {
  width: 4em;
  height: 4em;
}

.objectcontainer {
  display: flex;
}

.objectcontent {
  margin-left: 15px;
  font-size: 24px;
}

.IEEEAAST {
  position: absolute;
  width: 90px;
}

iframe,
object,
embed {
  max-width: 100%;
}

.countdown {
  font-size: 4vw;
  padding: 40px;
  text-align: center;
}

.countdown p {
  font-size: 24px;
}

@media (max-width: 1024px) {
  .imagecontent {
    margin-top: 60px;
    margin-right: auto;
    margin-left: auto;
  }
  .Content {
    margin: 80px 80px 0px 80px;
  }
  .Titlecontainer {
    padding-left: 120px;
    padding-right: 120px;
  }
  .Logo {
    top: 13%;
    width: 15%;
  }
  .Title {
    font-size: 48px;
  }
}

@media (max-width: 768px) {
  .Content {
    margin: 50px 50px 0px 50px;
  }
  .Topic {
    font-size: 8vw;
  }
  .Logo {
    top: 13%;
    left: 5%;
    width: 15%;
  }
  .Titlecontainer {
    padding-left: 80px;
    padding-right: 80px;
  }
  .Title {
    font-size: 36px;
  }
  .countdown {
    font-size: 6vw;
    padding: 10px;
    text-align: center;
  }
  .Details {
    font-size: 4vw;
  }
  .objectcontent {
    font-size: 4vw;
  }
  /* .Headerr{
        width: 100%;
        height: 1100px;
        background-size: cover;
        background-position: center;
        background: url(../../Images/homeHeader.png);
    } */
}
